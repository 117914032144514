import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';

export default class Form {

    constructor(options) {
        this.$el = options.$el;
        this.$submit = this.$el.querySelector('.js-submit');
        
        this._onSubmit = this.onSubmit.bind(this);
        this.addEvent();
        this.sended = false;
    }

    addEvent() {
        this.$el.addEventListener('submit', this._onSubmit);
    }

    onSubmit(e){

        if (!this.$el.checkValidity() || this.sended ) {
            return;
        } else {
            e.preventDefault();
            
            this.sendEmail();
        }
    }

    sendEmail(){

        
        let formData = new FormData(this.$el);

        fetch('/public/php/mail/index.php', { method: 'POST', body: formData }).then((response) => {
            if (response.status == 200) {
                response.text().then((result) => {
                    if (result.length<7) { // should return an id, instead of a long string error
                        this.$submit.textContent = "✓";
                        this.sended = true;
                    } else {
                        this.$submit.textContent = "Error";
                        this.errorWarn(result);
                    }
                });
            } else {
                this.$submit.textContent = "Error";
                this.errorWarn(response);
            }
        });
    }

    errorWarn(error) {
        console.warn("Issue while trying to send data to mailjet:", error);
    }
}
