import GetTopDistance from './GetTopDistance.js';
import Window from '../stores/Window.js';
import smoothscroll from 'smoothscroll-polyfill';


export default class Anchor {
    constructor(options) {
        this.$el = options.$el;
        smoothscroll.polyfill();
        
        this.$el.addEventListener('click', this.onAnchorClick.bind(this) );
    }

    onAnchorClick(){
        const anchor = document.querySelector(`${this.$el.getAttribute('data-anchor')}`);
        window.scrollTo({top: GetTopDistance(anchor) - Window.height/4, behavior: 'smooth' });
    }
}

