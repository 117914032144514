export default function getDistance(element) {
    let el = element;
    let distance = 0;

    if (!!el && el.offsetParent) {
        do {
            distance += el.offsetTop;
            el = el.offsetParent;
        } while (el);
    }

    return distance < 0 ? 0 : distance;
};