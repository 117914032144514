import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';

export default class Navigation {

    constructor(options) {
        this.$el = options.$el;
        this.$btn = this.$el.querySelector('.js-navigation-open');
        this.$menuMobile = this.$el.querySelector('.js-menu-mobile');
        this._onBurgerClick = this.onBurgerClick.bind(this);
        this._closeMenu = this.closeMenu.bind(this);

        Emitter.on('scroll', this.scroll.bind(this)); 

        this.scroll();
        this.addEvent();
    }

    addEvent(){
        this.$btn.addEventListener('click', this._onBurgerClick);
        [].slice.call(this.$el.querySelectorAll('.js-nav-link')).forEach(element => {
            element.addEventListener('click', this._closeMenu);
        });
    }

    closeMenu(){
        Emitter.emit('toggleScroll', false);
        this.$el.classList.remove('is-open');
        this.isOpen = false;
    }

    openMenu(){
        Emitter.emit('toggleScroll', true);
        this.$el.classList.add('is-open');
        this.isOpen = true;
    }

    onBurgerClick(){
        if( this.isOpen ){
            this.closeMenu();
        }else{
            this.openMenu();
        }
    }

    scroll(){
        if ( Window.scroll > 30 ){
            if ( !this.hasScrolled ){
                this.hasScrolled = true;
                this.showFixed();
            }
        }else{
            if (this.hasScrolled) {
                this.hasScrolled = false;
                this.showUnfix();
            }
        }
    }

    showUnfix(){
        this.$el.classList.remove('is-stuck');
    }

    showFixed() {
        this.$el.classList.add('is-stuck');
    }
}
