import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';

export default class ScrollBlocker {

    constructor(options) {
        this._toggleScroll = this.toggleScroll.bind(this);
        this.addEvents();
    }

    addEvents(){
        Emitter.on('toggleScroll', this._toggleScroll);
    }

    removeEvents(){
        Emitter.off('toggleScroll', this._toggleScroll);
    }

    toggleScroll(bool) {
        if (!bool) {
            if (!Window.scrollEnable) {
                document.documentElement.classList.remove('scrollDisabled');
                // Restore the previous scroll position
                window.scrollTo(0, this.scrollSave);
                Window.scrollEnable = true;
            }
        } else {
            if (Window.scrollEnable) {
                // We want to keep the scrollbar to prevent the content from jumping
                window.requestAnimationFrame(() => {
                    this.scrollSave = Window.scroll;
                    document.documentElement.style.top = "-" + Window.scroll + "px";
                    document.documentElement.classList.add('scrollDisabled');
                    Window.scrollEnable = false;
                });
            }
        }
    }

    destroy() {
        this.removeEvents();
    }
}
