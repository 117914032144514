export default class ActiveClassSwitch {

    constructor(options) {
        this.$el = options.$el;

        this.triggers = Array.prototype.slice.call(this.$el.querySelectorAll('.js-acs-trigger'));
        this.targets = this.triggers.map(elm => {
            return document.querySelector(elm.getAttribute('data-acs-target'));
        });

        this._onSwitch = this.onSwitch.bind(this);
        
        this.setEvents();
    }

    setEvents() {
        [].slice.call(this.$el.querySelectorAll('.js-acs-trigger')).forEach(element => {
            element.addEventListener('click', this._onSwitch);
        });
    }

    removeEvents() {
        [].slice.call(this.$el.querySelectorAll('.js-acs-trigger')).forEach(element => {
            element.removeEventListener('click', this._onSwitch);
        });
    }

    onSwitch(event) {
        event.preventDefault();

        const switchTrigger = event.currentTarget;

        if (!switchTrigger.classList.contains('is-active')) {
            let targetId = switchTrigger.getAttribute('data-acs-target');

            this.targets.forEach(element => {
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                }
            });
            this.triggers.forEach(element => {
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                }
            });

            switchTrigger.classList.add('is-active');
            if (!!targetId && document.querySelector(targetId) !== null) {
                document.querySelector(targetId).classList.add('is-active');
            }
        }
    }

    destroy() {
        this.removeEvents();
    }
}
