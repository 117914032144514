import Emitter from '../utilities/Emitter.js';
import Window from '../stores/Window.js';
import Smooth from '../utilities/smoothscroll.js';
import Debounce from '../utilities/Debounce.js';

export default class ScrollHandler {

    constructor(options) {
        this.$el = options.$el;

        this.$bg = document.querySelector('.js-fake-bg');

        this._onResize = this.onResize.bind(this);
        this._onScroll = this.onScroll.bind(this);
        this._resizeSmooth = this.resizeSmooth.bind(this);
        this.resizeSmoothDebounced = Debounce(this._resizeSmooth, 500);

        this.addEvent();
        this.createSmoothScroll();
        this.setAnchorDistance();
    }

    getDistance(element) {
        let el = element;
        let distance = 0;

        if (el.offsetParent) {
            do {
                distance += el.offsetTop;
                el = el.offsetParent;
            } while (el);
        }

        return distance < 0 ? 0 : distance;
    }

    setAnchorDistance(){
        setTimeout(() => {
            this.actualyOffset = this.getDistance(document.querySelector('#actually-anchor')) - Window.height / 2 + 60;
        });
    }

    addEvent() {
        Emitter.on('resize', this._onResize);
        Emitter.on('scroll', this._onScroll);
    }

    resizeSmooth(){
        this.masterScroll.resize(Window.width, Window.height);
    }
    
    createSmoothScroll() {
        this.masterScroll = new Smooth(
            this.$el,
            Window.scroll,
            Window.width,
            Window.height,
            true,
            0.1);

        Emitter.on('resizeScroll', this.resizeSmoothDebounced);
        
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            document.documentElement.classList.add('is-touch');
        }
    }

    onResize(){
        this.setAnchorDistance();
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            document.documentElement.classList.add('is-touch');
        } else {
            document.documentElement.classList.remove('is-touch');
        }
        this.resizeSmoothDebounced();
    }

    onScroll(){
        if (Window.scroll > this.actualyOffset ){
            this.$bg.classList.add('is-after-1st-trigger');
        }else{
            this.$bg.classList.remove('is-after-1st-trigger');
        }

        this.masterScroll.scroll.master = Window.scroll;
    }
}
