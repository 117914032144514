import './ResizeObserver.js';
import Emitter from './Emitter.js';


const MathUtils = {
    // map number x from range [a, b] to [c, d]
    map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
    // linear interpolation
    lerp: (a, b, n) => a + (b - a) * n
};

const getDistance = function (element) {
    let el = element;
    let distance = 0;

    if (el.offsetParent) {
        do {
            distance += el.offsetTop;
            el = el.offsetParent;
        } while (el);
    }

    return distance < 0 ? 0 : distance;
};


class ParallaxItem {
    constructor(el, scroll) {
        this.$el = el;
        this.scroll = scroll;

        //Retreive our parameters
        this.distance = parseFloat(this.$el.getAttribute('data-distance') || 100) * -1;
        this.delay = parseFloat(this.$el.getAttribute('data-delay') || 0);
        this.classTrigger = this.$el.hasAttribute("data-class");
        this.offset = this.$el.getAttribute("data-offset") || '0px 0px 0px 0px';
        this.isHorizontal = !!this.$el.getAttribute('data-direction') && this.$el.getAttribute('data-direction') == 'horizontal';
        this.enterFn = window[this.$el.getAttribute("data-onEnter")] || false;
        this.leaveFn = window[this.$el.getAttribute("data-onLeave")] || false;

        this.translate = {
            current: scroll.fake,
            previous: scroll.fake,
            ease: 1.0 - this.delay
        };

        //If object is visible in the viewport or not
        this.visible = false;
        this.fisrtEnter = false;

        this.computeMetrics();
        this.createObserver();
    }

    createObserver() {
        if (true) {
            // use the IntersectionObserver API to check when the element is inside the viewport
            this.observerClass = new IntersectionObserver((entries) => {
                if (entries[0].intersectionRatio > 0) {
                    // if (!this.fisrtEnter) {
                    // this.$el.classList.add('is-enter-once');
                    if ( !!this.enterFn ){
                        this.enterFn();
                    }
                    this.fisrtEnter = true;
                    // }
                    // this.$el.classList.add('is-inview');
                } else {
                    if (this.fisrtEnter) {
                        if (!!this.leaveFn) {
                            this.leaveFn();
                        }
                    }
                    // this.$el.classList.remove('is-inview');
                }
            }, {
                rootMargin: this.offset,
                // threshold: 1.0
            });

            this.observerClass.observe(this.$el);
        }



        // use the IntersectionObserver API to check when the element is inside the viewport
        this.observer = new IntersectionObserver((entries) => {
            this.isVisible = entries[0].intersectionRatio > 0;
        }, {
            rootMargin: '30%', // Could probably be removed
            // threshold: 1.0
        });
        this.observer.observe(this.$el);
    }

    computeMetrics() {
        //Distance from top of document
        this.distanceTop = getDistance(this.$el);
        //Height of our pbject
        this.height = this.$el.getBoundingClientRect().height;
        //The position where the top of our object pass the bottomp of the viewport
        this.fakeEnterTrigger = this.distanceTop - window.innerHeight;
        if (!this.isHorizontal) {
            this.fakeEnterTrigger -= this.distance / 2;
        }
        //The position where the top of our object pass the bottomp of the viewport
        this.fakeLeaveTrigger = this.distanceTop + this.height;
        if (!this.isHorizontal) {
            this.fakeLeaveTrigger += this.distance / 2;
        }
        //The distance to view
        this.distanceToDo = this.fakeLeaveTrigger - this.fakeEnterTrigger;
    }

    resize() {
        this.computeMetrics();
    }

    onResize(e) {
        Emitter.emit('resizeScroll');
    }

    getProgress() {
        // (distanceDone / distanceToDo)
        return (this.scroll.fake - this.fakeEnterTrigger) / this.distanceToDo;
    }

    addEvents() {
        addResizeListener(this.$el, this.onResize);
    }

    removeEvents() {
        removeResizeListener(this.$el, this.onResize);
    }

    update(force) {
        if (force || this.isVisible) {
            let progress = Math.min(Math.max(this.getProgress(), 0), 1);

            progress -= 0.5;

            this.translate.previous = this.translate.current;
            this.translate.current = MathUtils.lerp(progress * this.distance, this.translate.current, this.delay);

            this.render();
        }
    }

    render() {
        if (this.translate.previous !== this.translate.current) {
            if (this.isHorizontal) {
                this.$el.style.transform = `translate3d(${this.translate.current}px,0,0)`;
            } else {
                this.$el.style.transform = `translate3d(0,${this.translate.current}px,0)`;
            }
        }
    }


    removeObserver() {
        !!this.observerClass && this.observerClass.disconnect();
        !!this.observer && this.observer.disconnect();
    }

    destroy() {
        this.removeEvents();
        this.removeObserver();
    }
}

class SlaveSmoothScroll {
    constructor(el, scroll, enable = true) {
        this.$el = el;
        this.scroll = scroll;
        this.enable = enable;

        this.visible = false;

        this.parallaxItems = [];
        this.setParallaxItems();
        this.addEvents();


        this.computeMetrics();
        if (this.parallaxItems.length > 0) {
            this.findExtrimities();
        }

        this.updateParallaxItems(true);
    }

    addEvents() {
        addResizeListener(this.$el, this.onResize);
    }

    removeEvents() {
        removeResizeListener(this.$el, this.onResize);
    }

    computeMetrics() {
        this.height = this.$el.getBoundingClientRect().height;
        this.distanceTop = getDistance(this.$el);

        this.limit = {
            top: this.distanceTop,
            bottom: this.distanceTop + this.height
        };

        //The position where the top of our object pass the bottomp of the viewport
        this.fakeEnterTrigger = this.distanceTop - window.innerHeight;
        //The position where the top of our object pass the bottomp of the viewport
        this.fakeLeaveTrigger = this.distanceTop + this.height;

        this.extrimities = {
            "enter": this.fakeEnterTrigger,
            "leave": this.fakeLeaveTrigger
        };
    }

    onResize(e) {
        Emitter.emit('resizeScroll');
    }

    resize() {
        for (let index = 0; index < this.parallaxItems.length; index++) {
            this.parallaxItems[index].resize();
        }
        this.computeMetrics();
        if (this.parallaxItems.length > 0) {
            this.findExtrimities();
        }
    }

    //Not the prettier, but very efficient
    findExtrimities() {
        let lowest = this.fakeEnterTrigger;
        let highest = this.fakeLeaveTrigger;
        let tmp;
        for (let i = this.parallaxItems.length - 1; i >= 0; i--) {
            tmp = this.parallaxItems[i].fakeEnterTrigger;
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;

            tmp = this.parallaxItems[i].fakeLeaveTrigger;
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
        }

        this.extrimities = {
            "enter": lowest,
            "leave": highest
        };
    }

    setParallaxItems() {
        this.$el.querySelectorAll('*[data-scroll-item]').forEach((item) => {
            this.parallaxItems.push(new ParallaxItem(item, this.scroll));
        });
    }

    updateParallaxItems(force = false) {
        for (var i = 0, n = this.parallaxItems.length; i < n; ++i) {
            this.parallaxItems[i].update(force);
        }
    }

    hide() {
        if (!this.visible) {
            // console.log('opacity off');
            this.$el.style.opacity = 0;
            this.$el.style.transform = `translate3d(0,0,0)`;
            this.visible = true;
        }
    }

    animate() {
        this.$el.style.transform = `translate3d(0,${-1 * this.scroll.fake}px,0)`;
        if (this.visible) {
            // console.log('opacity on');
            this.$el.style.opacity = 1;
            this.visible = false;
        }
    }

    update() {
        if (!!this.extrimities) {
            if (this.enable) {
                if (this.scroll.fake > this.extrimities.leave || this.scroll.fake < this.extrimities.enter) {
                    this.hide();
                } else {
                    this.animate();
                    this.updateParallaxItems();
                }
            } else {
                this.updateParallaxItems();
            }
        }
    }

    destroy() {
        this.parallaxItems.forEach(item => {
            item.detroy();
        });
        this.parallaxItems = null;
        this.removeEvents();
        this.destroy();
    }
}

// SmoothScroll
export default class MasterSmoothScroll {
    constructor(el, scrollPosition, screenWidth, screenHeight, scrollbar = true, ease = 0.3) {
        this.$el = el;
        //You're in charge of providing the current scrollPos. (prevent having mutiple script retreiving the same value if you have a store)

        this.scroll = {
            master: scrollPosition,
            fake: scrollPosition
        };

        this.screen = {
            width: screenWidth,
            height: screenHeight,
        };

        this.ease = ease;
        this.scrollbar = scrollbar;

        this.scrollAreas = this.$el.querySelectorAll('*[data-scroll]');
        this.fakeScrollDiv = document.createElement('div');
        this.fakeScrollAppended = false;

        this.handleScrollbar();
        // set slaves
        this.setSlaves();
        //Create the fake that allow a scrollBar to appear
        this.makeFakeScrollDiv();
        // start the render loop
        requestAnimationFrame(() => this.render());
    }

    handleScrollbar() {
        if (!this.scrollbar) {
            document.documentElement.classList.add('hide-scrollbar');
        }
    }

    resize(screenWidth, screenHeight) {
        this.screen = {
            width: screenWidth,
            height: screenHeight,
        };

        for (let index = 0; index < this.slaveScroll.length; index++) {
            const element = this.slaveScroll[index];
            this.slaveScroll[index].resize();
        }

        requestAnimationFrame(() => {
            this.makeFakeScrollDiv();
        });
    }

    makeFakeScrollDiv() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) return;
        let totalHeight = 0;

        this.scrollAreas.forEach(item => {
            totalHeight += item.getBoundingClientRect().height;
        });

        //Update the fake scrollDiv only if size had change
        if (!this.fakeScrollAppended || totalHeight !== this.totalHeight) {
            this.fakeScrollDiv.style.height = totalHeight + 'px';
        }
        this.totalHeight = totalHeight;

        if (!this.fakeScrollAppended) {
            document.body.appendChild(this.fakeScrollDiv);
            this.fakeScrollAppended = true;
        }
    }

    setSlaves() {
        this.slaveScroll = [];
        this.scrollAreas.forEach((item) => {
            if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
                this.slaveScroll.push(new SlaveSmoothScroll(item, this.scroll, false));
            } else {
                this.slaveScroll.push(new SlaveSmoothScroll(item, this.scroll, true));
            }
        });
    }

    render() {
        //Prevent to cumpute new value if the change is less than half of a pixel
        if (Math.abs(this.scroll.fake - this.scroll.master) > 0.499) {
            //Save the computed fake scroll value
            this.scroll.fake = MathUtils.lerp(this.scroll.fake, this.scroll.master, this.ease);
        }

        //Update all our slave with our current value
        for (var i = 0, n = this.slaveScroll.length; i < n; ++i) {
            this.slaveScroll[i].update();
        }

        requestAnimationFrame(() => this.render());
    }

    destroy() {
        this.slaveScroll.forEach((item) => {
            item.destroy();
        });
        this.slaveScroll = null;
    }
}