import Emitter from '../utilities/Emitter.js';

class Window {

    constructor() {
        this.width = null;
        this.height = null;

        this.isMobile = null;
        this.isIpad = null;
        this.isTablet = null;
        this.isTouch = null;

        this.scrollEnable = true;

        this.scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        this.smoothScroll = this.scroll;

        this.resize();

        // Listen global events
        window.addEventListener('scroll', this.onScroll.bind(this), false);
        Emitter.on('resize', this.resize.bind(this));
    }

    resize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.isMobile = window.innerWidth < 768;
        this.isTablet = window.innerWidth < 1023;
    }

    onScroll() {
        this.scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
    }

    update() {

    }

}

const dataWindow = new Window;

export default dataWindow;
