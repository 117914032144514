import '../styles/index.scss';

// require('es6-promise').polyfill();
require('./utilities/objectAssign.js');

import Window from './stores/Window.js';
import Emitter from './utilities/Emitter.js';
import ScrollBlocker from './utilities/ScrollBlocker.js';
import Navigation from './components/Navigation.js';
import Anchor from './utilities/Anchor.js';
import Form from './components/Form.js';
import ScrollHandler from './components/ScrollHandler.js';
import ActiveClassSwitch from './utilities/ActiveClassSwitch.js';


const components = [
    { el: '.js-navigation', component: Navigation },
    { el: '.js-anchor', component: Anchor },
    { el: '.js-form', component: Form },
    { el: '.js-scroll-handler', component: ScrollHandler },
    { el: '.js-class-switcher', component: ActiveClassSwitch }
];



class App {
    constructor() {
        this._components = [];

        window.addEventListener('resize', this.resize, false);
        window.addEventListener('scroll', this.scroll, false);

        this._update = this.update.bind(this);

        this.update();

        new ScrollBlocker();
        this.createComponents(components);
    }

    createComponents(components) {
        for (let i = 0, l = components.length; i < l; i++) {
            let elements = document.querySelectorAll(components[i].el);

            Array.prototype.forEach.call(elements, (el) => {
                let params = { $el: el };
                if (!!components[i].params) Object.assign(params, components[i].params);

                const view = new components[i].component(params);

                this._components.push(view);
            });
        }
    }

    update() {
        Emitter.emit('update');
        requestAnimationFrame(this._update);
    }

    resize() {
        Emitter.emit('resize');
    }

    scroll() {
        if (Window.scrollEnable) {
            Emitter.emit('scroll');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});